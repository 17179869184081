import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { IBrandOnboardResponse } from './brand-onboard.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BrandOnboardSharedService {

  private dataSubject = new BehaviorSubject<IBrandOnboardResponse>({} as IBrandOnboardResponse);

  public sellerInfoObservable = this.dataSubject.asObservable();

  private inCompleteStep = new BehaviorSubject<boolean>(false);

  public inCompleteStepObservable = this.inCompleteStep.asObservable();

  private loadingState = new BehaviorSubject<boolean>(false);


  // private loadingState = new BehaviorSubject<boolean>(false);
  // loadingStateObservable = this.loadingState.asObservable().pipe(
  //   distinctUntilChanged() // Ensures only unique values trigger the subscription
  // );

  public loadingStateObservable = this.loadingState.asObservable();

  private formDataSubject = new BehaviorSubject<FormGroup>({} as FormGroup);

  public formDataObservable = this.formDataSubject.asObservable();

  setBrandOnboardData(data: IBrandOnboardResponse) {
    this.dataSubject.next(data);
  }

  getBrandOnboardData() {
    return this.dataSubject.getValue();
  }

  setInCompleteStep(data: boolean) {
    this.inCompleteStep.next(data);
  }

  getInCompleteStep() {
    return this.inCompleteStep.getValue();
  }


  getLoadingState() {
    return this.loadingState.getValue();
  }

  setLoadingState(data: boolean) {
    this.loadingState.next(data)
  }

  constructor() { }

  updateFormData(formData: FormGroup) {
    this.formDataSubject.next(formData);
  }
}
