import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';
import { RestApiService } from './api.service';
import { HttpClientService } from './http-client.service';

export interface IEntity {
  entityId: string,
  entityType: string
}

export interface ILocation {
  type: 'Point';
  // coordinates: [number, number];
  coordinates: [string, string];
}

export interface IImageUpload {
  s3FileName: string,
  originalFileName: string,
  fileType?: string,
  contentType?: string,
  photoUrl?: {
    url: string,
    expiresIn: number
  }
}

export interface IAssets extends IImageUpload {
  contentType: string;
  fileType: string;
  fileUrl?: {
    url: string
  },
}

export interface ICuisine {
  cuisineId: string;
  cuisineName: string;
  cuisineAliasName?: string;
  cuisineDescription?: string;
}

export interface ISellerOnboardResponse {
  sellerInfoId: string,
  createdAt: string,
  createdBy: IEntity,
  isOtpVerified: boolean,
  parentZoneId: string,
  subZoneId: string,
  phoneNumber: string,
  platform: string,
  screeningStatus: string,
  screeningStatusRemarks?: string;
  sellerName: string,
  updatedAt: string,
  whatsAppUpdates: boolean,
  identity_username: string,
  city?: string,
  processStatus?: string,
  sellerStatus?: string,
  inspectionStatus?: string,
  updatedBy: IEntity

  preScreening: {
    sellerCategory: string[],
    kitchenFoodType: string[],
    isHalal: boolean,
    fssaiNumber?: string,
    cuisines: ICuisine[],
    mealTypes: string[],
    hasPreviousExperience: boolean,
    committedDaysPerMonth: number,
    isDrafted: boolean,
    submittedAt?: string,
    updatedBy?: IEntity
  }
  personalInfo: {
    title: string;
    age: number,
    altPhoneNumber: string,
    emailId: string,
    isDrafted: boolean,
    updatedBy: IEntity,
    isInOurZone?: boolean,
    pickUpLocation: {
      location: ILocation
      address: string,
      landmark: string,
      city: string,
      state: string,
      pinCode: string,
    }
  },
  fssaiAndTermsConditions: {
    fssaiNumber?: string,
    fssaiCertificateImage?: IImageUpload,
    isTermsAndConditionsAgreed: boolean,
    fssaiReferenceNumber: string,
    submittedAt: string,
    updatedBy: IEntity,
    isDrafted?: boolean,
    fssaiExpiryDate?: Date;
  },
  bankDetailsAndTax: {
    isGstInAvailable: boolean,
    isGstInVerified: boolean,
    isPanVerified: boolean,
    isAadhaarVerified: boolean,
    bankDetails: {
      accountHolderName: string,
      accountNumber: string,
      ifscCode: string,
      proofImage: IImageUpload
    },
    maskedGstInNumber: string,
    maskedPanNumber: string,
    maskedAadhaarNumber: string,
    isProvideLater?: boolean,
    updatedBy: IEntity,
    submittedAt: string,
    isDrafted: boolean
  },
  inspectionInitiation: {
    kitchenOrMftPlaceImage: Array<IImageUpload>,
    isSellerReady: boolean,
    isDrafted: boolean,
    scheduledDate?: string,
    updatedBy: IEntity,
    submittedAt: string,
  }
  profile?: {
    kitchenProfile: {
      kitchenProfileImage: IImageUpload,
      assets: IAssets[],
      kitchenName: string,
      cuisines: ICuisine[],
      kitchenType: string,
      kitchenDescription: string,
      isHalal?: boolean
    },
    sellerProfile: {
      brandProfileImage: IImageUpload,
      assets: IAssets[],
      brandName: string,
      sellerCategory: string[]
    },
    isDrafted: boolean,
    updatedBy: IEntity,
    submittedAt: string
  },
  profileApprovalStatus?: string;
  rejectedReason?: string;
  kitchenId?: string;
  cookId?: string;
}

export interface IPreScreening {
  fssaiNumber?: string,
  isDrafted: boolean,
  isHalal?: boolean,
  kitchenFoodType?: Array<string>,
  parentZoneId?: string,
  sellerCategory: string[],
  subZoneId?: string,
  city?: string,
  otherArea?: string,
  cuisines: ICuisine[],
  mealTypes: string[],
  hasPreviousExperience: boolean,
  committedDaysPerMonth: number,
  submittedAt?: string,
  updatedBy?: IEntity
}

export interface IPersonalDetails {
  age?: number,
  title: string;
  altPhoneNumber: number,
  emailId: string,
  pickUpLocation: {
    location?: {
      type?: string,
      coordinates?: number[],
    }
    address: string,
    landmark: string,
    city: string,
    state: string,
    pinCode: string,
  }
  isDrafted: boolean
}

export interface IFssaiDetails {
  fssaiNumber?: string,
  fssaiCertificateImage?: IImageUpload,
  fssaiReferenceNumber?: string,
  isTermsAndConditionsAgreed: boolean,
  isDrafted: boolean,
  fssaiExpiryDate: string;
}

export interface IBankTaxDetails {
  isGstInAvailable: boolean,
  maskedGstInNumber?: string,
  isGstInVerified?: boolean,
  maskedPanNumber?: string,
  isPanVerified?: boolean,
  maskedAadhaarNumber?: string,
  isAadhaarVerified?: boolean,
  bankDetails?: {
    accountHolderName: string,
    accountNumber: string,
    ifscCode: string,
    proofImage?: IImageUpload
  }
  isDrafted: boolean,
  isProvideLater?: boolean,
  aadhaarNumber?: string,
  panNumber?: string,
  gstInNumber?: string,
}


export interface IVisualVerificationDetails {
  kitchenOrMftPlaceImage: Array<IImageUpload>,
  isSellerReady: boolean,
  isDrafted: boolean
}

export interface createCuisine {
  cuisineName: string;
  cuisineAliasName: string;
  cuisineDescription: string;
}

export interface IVerifyGST {
  legal_name_of_business: string;
  trade_name_of_business: string;
  nature_of_business_activities: Array<string>;
  principal_place_address: string;
}
export interface IGenerateOTP {
  ref_id: string;
  status: string;
  message: Array<string>;
}

export interface IVerifyOTP {
  ref_id: string;
  otp: string;
}

export enum ScreeningStatus {
  PENDING = 'pending',
  WAITING = 'waiting',
  REJECTED = 'rejected',
  INREVIEW = 'inReview'
}

export enum SellerStatus {
  INITIATE_SCREENING = 'initiateScreening',
  INITIATE_INSPECTING = 'initiateInspecting',
  INITIATE_ONBOARDING = 'initiateOnboarding'
}

export enum KitchenInspectionStatus {
  SCHEDULED = 'scheduled',
  RESCHEDULED = 'reScheduled',
  UNSCHEDULED = 'unScheduled'
}

export interface ISubZoneDetails {
  parentZoneId: string;
  subZoneId: string;
  active: boolean;
  subZoneName: string;
  subZoneAliasName: string;
  inactiveMessageId: string;
  createdAt: string;
  updatedAt: string;
  radiusInKms: string;
}

export interface ICallSupport {
  sellerInfoId: string,
  phoneNumber: string,
  parentZoneId?: string,
  subZoneId?: string
}

@Injectable()
export class SellerOnboardApiService extends RestApiService {


  protected override baseApiUrl = environment.config['SELLER_PORTAL_SERVICE_URI'];

  private servicePath = `${this.baseApiUrl}/seller-portal-v2/`;

  private idToken = localStorage.getItem('idToken') || '';

  private sellerInfoId = localStorage.getItem('sellerInfoId') || ''

  private headers: any;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;

    this.headers = {
      'x-auth-provider': 'google',
      'user-current-view': 'cook',
      'idToken': this.idToken
    }
  }

  async createPreScreeningDetails(payload: IPreScreening, sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}pre-screening/${sellerInfoId}`;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async updatePreScreeningDetails(payload: IPreScreening, sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}pre-screening/${sellerInfoId}`;
    return this.httpPut(apiPath, payload, this.headers);
  }

  async createPersonalDetails(payload: IPersonalDetails, sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}personal-details/${sellerInfoId}`;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async createFssaiDetails(payload: IFssaiDetails, sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}fssai-and-terms-condition/${sellerInfoId}`;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async updateFssaiDetails(payload: IFssaiDetails, sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}fssai-and-terms-condition/${sellerInfoId}`;
    return this.httpPut(apiPath, payload, this.headers);
  }

  async createOrUpdateBankTaxDetails(payload: IBankTaxDetails, methodType: 'post' | 'put', sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}bank-details-and-tax/${sellerInfoId}`;
    if (methodType === 'put') {
      return this.httpPut(apiPath, payload, this.headers);
    } else if (methodType === 'post') {
      return this.httpPost(apiPath, payload, this.headers);
    } else {
      throw new Error(`Unsupported methodType: ${methodType}`);
    }
  }

  async verifyGst(sellerInfoId: string, payload: { GSTIN: string; }): Promise<{ data: IVerifyGST }> {
    const apiPath = `${this.servicePath}gst-in-verification/${sellerInfoId}`;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async verifyPan(sellerInfoId: string, payload: { pan: string; }): Promise<{ data: any }> {
    const apiPath = `${this.servicePath}pan-card-verification/${sellerInfoId}`;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async generateOtp(payload: { aadhaarNumber: string; }): Promise<{ data: IGenerateOTP }> {
    const apiPath = `${this.servicePath}aadhaar-verification/generate-otp`;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async verifyOtp(payload: IVerifyOTP): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}aadhaar-verification/verify-otp/${this.sellerInfoId}`;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async createVisualVerificationDetails(payload: IVisualVerificationDetails, sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}inspection-initiation/${sellerInfoId}`;
    return this.httpPost(apiPath, payload, this.headers);
  }

  async updateVisualVerificationDetails(payload: IVisualVerificationDetails, sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}inspection-initiation/${sellerInfoId}`;
    return this.httpPut(apiPath, payload, this.headers);
  }

  async getSellerInfoById(sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}${sellerInfoId}`;
    return this.httpGet(apiPath, this.headers);
  }

  async updateSellerInfo(payload: ISellerOnboardResponse, sellerInfoId: string = this.sellerInfoId): Promise<{ data: ISellerOnboardResponse }> {
    const apiPath = `${this.servicePath}${sellerInfoId}`;
    return this.httpPut(apiPath, payload, this.headers);
  }

  async get(id: string): Promise<ICuisine> {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpGet(apiPath);
  }

  async getAll(): Promise<ICuisine[]> {
    const apiPath = `${this.servicePath}`;
    return this.httpGet(apiPath);
  }

  async create(payload: createCuisine) {
    const apiPath = `${this.servicePath}`;
    return this.httpPost(apiPath, payload);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpDelete(apiPath);
  }

  async update(id: string, payload: Partial<createCuisine>) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpPut(apiPath, payload);
  }

  async getLocationByCoordinates(lat: string, lng: string): Promise<{ data: { subZones: Array<ISubZoneDetails> } }> {
    const apiPath = `${this.servicePath}locate/sub-zone?long=${lng}&lat=${lat}`;
    return this.httpGet(apiPath, this.headers);
  }

  async refreshTokenApi(): Promise<any> {
    const payload = {
      refreshToken: localStorage.getItem('refreshToken')
    }
    const apiPath = `${this.baseApiUrl}/gcp-store/refreshToken`;
    return this.httpPost(apiPath, payload);
  }

  async initiateCallSupport(payload: ICallSupport) {
    const apiPath = `${this.servicePath}initiate-call-support`;
    return this.httpPost(apiPath, payload, this.headers);
  }

}