import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { RestApiService } from '../services/api.service';
import { HttpClientService } from '../services/http-client.service';

export interface IRegisterUser {
  sellerName: string;
  phoneNumber: string;
  captchaToken: string;
  parentZoneId?: string;
  city?: string;
  platform?: string;
  whatsAppUpdates: string;
}

export interface IOtpInput {
  phoneNumber: string;
  sessionId: string;
  passCode: string;
  name?: string;
  userLogin?: boolean;
  kitchenName?: string;
  kitchenId?: string;
  cookId?: string;
  cookName?: string;
  isCookOnboardedOrActive?: boolean;
  isPending?: boolean;
  isRejected?: boolean;
}

export interface IRefreshToken {
  refreshToken: string;
}

export interface IZones {
  parentZoneId: string;
  parentZoneName: string;
}
export interface ISubZones {
  parentZoneId: string;
  subZoneId: string;
  subZoneName: string;
}

interface IUserResponse {
  _id?: string;
  identity_username?: string;
  userName?: string;
  mobileNumber?: string;
  zone?: string;
  isOtpVerified?: string;
  businessAddress?: string;
  sellerPipelineId?: string;
  fssaiNumber?: string;
  foodType?: string;
  isFssaiRegistered?: boolean;
  leadType?: string;
  category?: Array<string>;
  lat?: string;
  long?: string;
  otherCity?: string;
}
export interface IUpdateUser {
  businessAddress?: string;
  sellerPipelineId?: string;
  identity_username?: string;
  userName: string;
  mobileNumber: string;
  fssaiNumber?: string | null;
  foodType?: string;
  isFssaiRegistered?: boolean;
  leadType?: string;
  category?: Array<string>;
  lat?: string;
  long?: string;
  zone?: string;
  otherCity?: string;
}

export enum zoneEnum {
  OTHERS = 'others',
}

export interface IUserLoginResponse {
  data: {
    sessionId: string;
  }
}

// Store Front Brands

export interface ISendOtpPayload_Brand {
  phoneNumber: string
}

export interface IVerifyOtpPayload_Brand {
  phoneNumber: string,
  passCode: string,
  sessionId: string
}


@Injectable()
export class PortalApiService extends RestApiService {
  protected adminBaseApiUrl = environment.config['SELLER_PORTAL_SERVICE_URI'];

  private adminServicePath = `${this.adminBaseApiUrl}`;

  private readonly headers: any;

  private idToken = localStorage.getItem('idToken') || ''

  constructor(http: HttpClientService) {
    super(http);
    this.headers = {
      ['x-auth-provider']: 'google',
      ['user-current-view']: 'cook',
      idtoken: this.idToken,
    }
  }

  async registerUser(payload: IRegisterUser) {
    const apiPath = `${this.adminServicePath}/gcp-store/send-verification-code`;
    return this.httpPost(apiPath, payload);
  }

  async verifyOtp(payload: IOtpInput) {
    const apiPath = `${this.adminServicePath}/gcp-store/verify-otp`;
    return this.httpPost(apiPath, payload);
  }

  async getUserDetailsById(userId: string): Promise<IUserResponse> {
    const apiPath = `${this.adminBaseApiUrl}/seller-portal/${userId}`;
    return this.httpGet(apiPath);
  }

  async updateUserDetailsById(payload: IUpdateUser) {
    const apiPath = `${this.adminBaseApiUrl}/seller-portal/update-leads`;
    return this.httpPut(apiPath, payload);
  }

  async fetchZones(): Promise<{ data: IZones[] }> {
    const apiPath = `${this.adminBaseApiUrl}/seller-portal-v2/zone/parent-zone`;
    return this.httpGet(apiPath, this.headers);
  }

  async fetchSubZones(parentZoneId: string): Promise<{ data: ISubZones[] }> {
    const apiPath = `${this.adminBaseApiUrl}/seller-portal-v2/zone/sub-zone/${parentZoneId}`;
    return this.httpGet(apiPath, this.headers);
  }

  async userLogin(payload: any) {
    const apiPath = `${this.adminBaseApiUrl}/gcp-store/check/user-existence`;
    return this.httpPost(apiPath, payload);
  }

  async sendOTP(payload: ISendOtpPayload_Brand) {
    const apiPath = `${this.adminBaseApiUrl}/gcp-store/brand/sign-in`;
    return this.httpPost(apiPath, payload);
  }

  async verifyOTP(payload: IVerifyOtpPayload_Brand) {
    const apiPath = `${this.adminServicePath}/gcp-store/brand/verify-otp`;
    return this.httpPost(apiPath, payload);
  }
}
