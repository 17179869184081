export const environment = {
  envName: 'dev',
  production: false,
  config: {
    SELLER_PORTAL_SERVICE_URI: 'https://dev.cookr.in/api/seller-pipeline-service/v2',
    SELLER_PORTAL_SERVICE_URI_V1: 'https://dev.cookr.in/api/seller-pipeline-service/v1',
    KITCHEN_SERVICE_URI: 'https://dev.cookr.in/api/kitchen-service/v2',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCUlqQY-y4HE9uQ6QYNlz-A7ovI7drPgJE',
    TRUEWAY_URL: 'https://trueway-geocoding.p.rapidapi.com/',
    TRUEWAY_KEY: '6f39a708eemsh3cacc32530ea56ep162863jsnba13bbf7c346',
    SMARTECH_CREATE_KEY: 'ADGMOT35CHFLVDHBJNIG50K969RFND2ARFMU9OVLHFTU349HHEQG',
    SMARTECH_REGISTER_KEY: '25014150918f0eab6dd58e0f55a0bea7',
    recaptcha: {
      siteKey: '6LfzR7gmAAAAAJjwm2KBKBEittgADx6S-AEcKwxc',
    },
  },
};
