import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SellerOnboardSharedService } from '../services/seller-onboard-shared.service';
import { IBankTaxDetails, ISellerOnboardResponse, ScreeningStatus, SellerOnboardApiService, SellerStatus } from '../services/seller-onboard.service';
import { HotToastService } from '@ngneat/hot-toast';
import { ProfileApprovalStatusEnum } from 'src/constants/approval-log.contants';
import { BrandOnboardSharedService } from 'src/services/brand-onboard-shared-service';
import { BrandOnboardApiService, IBrandOnboardResponse, IPreScreening } from 'src/services/brand-onboard.service';

const onboardingTabStatus = {
  default: 'NOT STARTED',
  notStarted: 'NOT STARTED',
  inProgress: 'IN PROGRESS',
  completed: 'COMPLETED'
};

enum brandOnboardingSteps {
  PRESCREENING = 1,
  BANK_DETAILS = 2,
  VERIFICATION = 3,
  BRAND_SETUP = 4
}

const onboardingTabs: Array<{
  step: number,
  objKeyName: keyof IBrandOnboardResponse,
  tabName: string,
  route: string,
  status: string;
}> = [
    {
      step: brandOnboardingSteps.PRESCREENING,
      objKeyName: 'preScreening',
      tabName: 'Pre-screening',
      route: 'pre-screening',
      status: onboardingTabStatus.default,
    },
    {
      step: brandOnboardingSteps.BANK_DETAILS,
      objKeyName: 'taxAndBankDetails',
      tabName: 'Tax & Bank Details',
      route: 'bank-details',
      status: onboardingTabStatus.default,
    },
    {
      step: brandOnboardingSteps.VERIFICATION,
      objKeyName: 'verification',
      tabName: 'Verification',
      route: 'verification',
      status: onboardingTabStatus.default,
    },
    {
      step: brandOnboardingSteps.BRAND_SETUP,
      objKeyName: 'brandSetup',
      tabName: 'Onboarding',
      route: 'brand-setup',
      status: onboardingTabStatus.default,
    },
  ];

@Injectable({
  providedIn: 'root',
})

export class BrandOnboardAuthGuard implements CanActivate {

  public currentStep = 1;

  public brandInfo!: IBrandOnboardResponse;

  public onboardingTabs = onboardingTabs;

  public sellerStatus = SellerStatus;

  constructor(
    private router: Router,
    private brandOnboardService: BrandOnboardApiService,
    private brandOnboardSharedService: BrandOnboardSharedService,
    private toast: HotToastService
  ) { }

  isAllBankDetailsExist() {
    const bankDetails = this.brandInfo?.taxAndBankDetails?.bankDetails;
    return bankDetails?.accountHolderName && bankDetails?.accountNumber && bankDetails?.ifscCode;
  }

  private async fetchBrandInfoById(brandInfoId: string) {
    try {
      let brandInfo = this.brandOnboardSharedService.getBrandOnboardData();
      if (brandInfo.hasOwnProperty('storeFrontBrandId') && brandInfoId === brandInfo.storeFrontBrandId) {
        return brandInfo;
      }
      // const response = await this.brandOnboardService.getSellerInfoByPhoneNumber('9876543211');
      const response = await this.brandOnboardService.getBrandDetailsById(brandInfoId);
      brandInfo = response?.data;
      if (brandInfo?.storeFrontBrandId) {
        this.brandOnboardSharedService.setBrandOnboardData(brandInfo);
        return brandInfo;
      } else {
        localStorage.clear();
        this.router.navigate(['/page-not-found']);
        return null;
      }
    } catch (error) {
      console.log('brand-onboard-auth.guard.ts: fetchBrandInfoById() - Unable to fetch the Brand info!', error);
      return null;
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // let storeFrontBrandId = localStorage.getItem('storeFrontBrandId');
    // let sellerInfoId = 'OCDPHFBS7U2bnWu3CtiGq';

    const trimmedBasePath = state.url.includes('brand-profile') ? state.url.slice('/brand-profile/'.length) : state.url.slice('/brand-onboard/'.length);
    let storeFrontBrandId = trimmedBasePath.slice(0, trimmedBasePath.indexOf('/'));
    console.log('storeFrontBrandId:', storeFrontBrandId);
    localStorage.setItem('storeFrontBrandId', storeFrontBrandId);



    if (!storeFrontBrandId) {
      this.toast.error('Brand not found!');
      this.router.navigate(['/page-not-found']);
      return false;
    }

    this.brandInfo = await this.fetchBrandInfoById(storeFrontBrandId) as IBrandOnboardResponse;
    if (!this.brandInfo) {
      this.toast.error('Brand not found!');
      this.router.navigate(['/page-not-found']);
      return false;
    }

    // Brand profile has been approved
    if (this.brandInfo?.status === ProfileApprovalStatusEnum.APPROVED || this.brandInfo?.isBrandOnboarded) {
      localStorage.setItem('storeFrontBrandId', this.brandInfo.storeFrontBrandId || '');
      // localStorage.setItem('cookId', this.brandInfo.cookId || '');
      localStorage.setItem('showOnboardingInitiated', 'true');
      const showBrandProfilePage = state.url.includes('brand-profile');
      if (showBrandProfilePage) {
        return true;
      }
      this.router.navigate(['/brand-profile', storeFrontBrandId]);
      return false;
    }

    // Brand profile has been rejected or in the waiting state
    // if (this.brandInfo.status === ScreeningStatus.REJECTED ||
    //   this.brandInfo.status === ScreeningStatus.WAITING) {
    //   const showProfileRejectionPage = state.url.endsWith('brand-setup');
    //   if (showProfileRejectionPage) {
    //     return true;
    //   }
    //   this.router.navigate(['brand-onboard', storeFrontBrandId, 'brand-setup']);
    //   return false;
    // }

    const lastEligibleStep = this.getLastEligibleStep();
    console.log('brand-onboard-auth.guard.ts: canActivate() - Eligible step:', lastEligibleStep);
    const requestedStep = this.getStepNumberFromRoute(state.url);
    console.log('brand-onboard-auth.guard.ts: canActivate() - requested step:', requestedStep);


    if (lastEligibleStep === brandOnboardingSteps.BRAND_SETUP &&
      (this.brandInfo?.status === ScreeningStatus.PENDING || this.brandInfo?.status === ScreeningStatus.INREVIEW) &&
      this.brandInfo.brandSetup.status.currentState === 'submitted') {
      if (requestedStep === lastEligibleStep) return true;
      this.router.navigate(['brand-onboard', storeFrontBrandId, this.getRouteFromStepNumber(lastEligibleStep)]);
      return false;
    }

    if (lastEligibleStep >= brandOnboardingSteps.PRESCREENING && lastEligibleStep <= brandOnboardingSteps.BRAND_SETUP) {
      if (requestedStep <= lastEligibleStep) return true;
      this.router.navigate(['brand-onboard', storeFrontBrandId, this.getRouteFromStepNumber(lastEligibleStep)]);
      return false;
    }
    else if (lastEligibleStep > this.onboardingTabs.length) {
      const showBrandProfilePage = state.url.includes('brand-profile');
      if (showBrandProfilePage) {
        return true;
      }
      this.router.navigate(['brand-profile', storeFrontBrandId]);
      return false;
    }
    this.router.navigate(['brand-onboard', storeFrontBrandId, this.getRouteFromStepNumber(lastEligibleStep)]);
    return false;
  }

  private getLastEligibleStep(): number {
    let draftedIndex = 0;
    let isLastPageDrafted = false;

    for (let [index, onboardingTab] of this.onboardingTabs.entries()) {
      const onboardingstep = this.brandInfo[onboardingTab.objKeyName];
      if (!onboardingstep) {
        if (isLastPageDrafted) {
          return this.onboardingTabs[draftedIndex].step;
        }
        return onboardingTab.step;
      }
      else if (onboardingstep && typeof onboardingstep === 'object') {
        if ('status' in onboardingstep && 'currentState' in onboardingstep.status) {
          if (onboardingstep.status.currentState === 'draft') {
            draftedIndex = index;
            isLastPageDrafted = true;
            if (onboardingTab.objKeyName === 'brandSetup') return onboardingTab.step;
          } else {
            isLastPageDrafted = false;
            if (onboardingTab.objKeyName === 'brandSetup') {
              if (this.brandInfo.status === 'approved') {
                localStorage.setItem('storeFrontBrandId', this.brandInfo.storeFrontBrandId || '');
                return this.onboardingTabs.length + 1; // User can go beyond the onboarding steps, i.e., they can access the profile page
              }
              return onboardingTab.step;
            }
          }
        }
        else {
          return onboardingTab.step;
        }
      }
    }
    return this.onboardingTabs.length + 1;
  }

  private getStepNumberFromRoute(url: string): number {
    let match = url.slice(url.lastIndexOf('/') + 1);
    if (match) {
      const step = this.onboardingTabs.find(tab => tab.route.toLowerCase() === match.toLowerCase());
      return step ? step.step : 1;
    }
    return 1; // Default to the first step if the URL format is not recognized
  }

  private getRouteFromStepNumber(step: number): string {
    const matchingTab = this.onboardingTabs.find(tab => tab.step === step);
    return matchingTab ? matchingTab.route : 'pre-screening'; // Default to pre-screening if no matching step is found
  }
}
